html {
  scroll-behavior: smooth;
}

body {
  background: $white;

}

body,
html {
  @extend %font-smoothing;
  @include font(normal,none, 14px, normal, 18px);
  font-family: $font-body;
  color                  : $black;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
  // @media(max-width: 991px) {
  //   font-size  : 13px;
  //   line-height: 20px;
  // }
}

main {
  @media (max-width: 991px) {
    overflow-x: hidden;
  }
}
