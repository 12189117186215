.home {
  &__hero {
    height: 650px;
    margin-top: 30px;
    background-size: cover;

    @media(max-width: 991px) {
      height: 420px;
    }

    @media(max-width: 767px) {
      height: 240px;
    }
  }

  &__history {
    &--content {
     padding: 50px 30px;
     text-align: center;

     p + p {
       margin-top: 20px;
     }

     p + a {
       margin-top: 25px;
       display: block;
     }
     
    }

    @media(max-width: 767px) {
      .col-xl-8 {
        border-bottom: 1px solid $dark-brown;
      }

      .bl-black {
        border-left: 0px;
      }
    }
  }

  &__location {
    .card:nth-child(even){
      margin-left: 30px;
    }

    & .pb-md-30.text-center {
      border-bottom: 1px solid $dark-brown;
      padding-bottom: 70px;
    }

    @media(max-width: 991px) {
      .justify-content-center {
        margin: 0;
      }
      .card:nth-child(even){
        margin-left: 0px;
      }

      .card__phone a {
        padding-top: 0px;
        padding-bottom: 0px;
      }

      .card span {
        padding-bottom: 6px;
      }

      .card__phone a+a:before {
        height: 40px;
        bottom: -1px;
        left: -10px;
      }
    }

    @media(max-width: 767px) {
      .pb-md-30.text-center {
        border-bottom: 1px solid $dark-brown;
        padding-bottom: 40px;
      }

      .card__phone a+a:before {
        height: 40px;
        bottom: -1px;
        left: -10px;
        width: 0;
      }

      .card__phone a+a {
        margin-left: 0px;
      }

      .card__phone a {
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }
  }


  &__follow {
    figure.element img {
      max-width: 110px;
    }
  }
  
}