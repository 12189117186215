%h1,
.h1,
h1 {
  @include font(normal,uppercase, 300px, 3px, 250px);
  @media(max-width: 991px) {
    @include font(normal,uppercase, 250px, 3px, 200px);
  }
  @media(max-width: 767px) {
    @include font(normal,uppercase, 120px, 1.44px, 100px);
  }
}

%h2,
.h2,
h2 {
  @include font(normal,uppercase, 100px, 1px, 70px);
  @media(max-width: 991px) {
    @include font(normal,uppercase, 70px, 0.7px, 70px);
  }
}

%h3,
.h3,
h3 {
  @include font(normal,uppercase, 70px, 0.7px, 70px);
}

%h4,
.h4,
h4 {
  @include font(500,uppercase, 13px, 0.46px, 15px);
  font-family: $font-body;

  @media(max-width: 991px) {
    @include font(500,uppercase, 16px, 0.57px, 20px);
    font-family: $font-body;
  }

  @media(max-width: 767px) {
    @include font(500,uppercase, 13px, 0.46px, 15px);
    font-family: $font-body;
  }
}


a, %a, address {
  @include font(500,uppercase, 18px, 0.64px, 20px);
  font-family: $font-black;

   @media(max-width: 991px) {
    @include font(500,uppercase, 16px, 0.51px, 20px);
    font-family: $font-black;
  }
}

