$menu-icon-dash-width:         0.75em;
$menu-icon-dash-height:        0.05em; 
$menu-icon-dash-gutter:        $menu-icon-dash-height * 5;


%menu-dash{
  display: block;
  position: absolute;
  top: 50%;
  left: (1em - $menu-icon-dash-width) / 2;
  background-color: $black;
  width: 21px;
  height: 2px;
  border-radius: 0px;
}

.menu-icon{
  font-size:28px;
  width: 30px;
  height: 30px;
  display: inline-block;
  position: absolute;
  background-color: transparent;
  border: 0;
  padding: 0;
  outline: none;
  user-select: none;
  -webkit-touch-callout: none;
  touch-action: manipulation;
  cursor: pointer;
  left:15px;
  top: 13px;
  z-index: 99;

  span{
    @extend %menu-dash;
    margin-top: $menu-icon-dash-height/-2;
  }

  &:before{
    content: "";
    @extend %menu-dash;
    margin-top: -$menu-icon-dash-gutter + ($menu-icon-dash-height/-2);
  }

  &:after{
    content: "";
    @extend %menu-dash;
    margin-top: $menu-icon-dash-gutter + ($menu-icon-dash-height/-2);
  }

  span{
    transition: 0.2s ease-in-out 0.2s;
  }

  &:before, &:after{
      transition: margin 0.2s ease-in-out 0.2s, transform 0.2s ease-in-out 0s;
  }

  &.active{
    span{
      background-color: rgba(white, 0);
      transition-delay: 0s;
    }

    &:before, &:after{
        margin-top: 0;
        transition-delay: 0s, 0.25s, 0.25s;
        background-color: white;
    }

    &:before{
        transform: rotate(45deg);
    }

    &:after{
        transform: rotate(-45deg);
    }
  }
}
