.card {
  border: 1px solid $dark-brown;
  padding: 0;
  margin-bottom: 30px;

  & > div {
    border-bottom: 1px solid $dark-brown;
  }

  span {
    @include font(500,uppercase, 13px, 0.46px, 15px);
    font-family: $font-black;
    display: block;
  }

  a, address {
    @include font(500,uppercase, 21px, 0.75px, 21px);
    font-family: $font-black;
  }

  img, p {
    display: inline-block;
  }

  &__phone {
    margin: 0;

    &--img {
      background-image: url(../img/phone.png);
      background-repeat: no-repeat;
      background-size:80%;
      background-position: center;
    }

    a {
      padding-top: 3px;
      display: block;
      position: relative;

      & + a {
        margin-left: 0px;

        // &::before {
        //   content:'';
        //   width: 1px;
        //   height: 50px;
        //   background: $dark-brown;
        //   position: absolute;
        //   bottom: -9px;
        //   left: -10px;
        // }
      }
    }
  }

  .map {
    height: 200px;
    padding: 20px;
    width: calc(100% - 40px);
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  figure {
    padding: 20px;
    margin-bottom: 0;
    img {
      width: 100%;
    }
  }

  &__body {
    padding: 20px 20px 20px 20px;
    border-bottom: none !important;
    span {
      display: inline-block;

      & + span::before {
        content: "";
        padding-left:10px;
        padding-right: 10px;
        background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iN3B4IiBoZWlnaHQ9IjdweCIgdmlld0JveD0iMCAwIDcgNyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTMuMiAoNzI2NDMpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPlJlY3RhbmdsZSBDb3B5IDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iaG9tZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ2NS4wMDAwMDAsIC0xNzkuMDAwMDAwKSIgZmlsbD0iIzIwMjAyMCI+CiAgICAgICAgICAgIDxnIGlkPSJoZWFkZXIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01LjAwMDAwMCwgNi4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJzdWN1cnNhbGVzIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMDguMDAwMDAwLCAxNDcuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZS1Db3B5LTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE2NS41MDAwMDAsIDI5LjUwMDAwMCkgcm90YXRlKC00NS4wMDAwMDApIHRyYW5zbGF0ZSgtMTY1LjUwMDAwMCwgLTI5LjUwMDAwMCkgIiB4PSIxNjMiIHk9IjI3IiB3aWR0aD0iNSIgaGVpZ2h0PSI1Ij48L3JlY3Q+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==');
        background-repeat: no-repeat;
        background-position: center top;
      }
    }

    p {
      margin-top: 10px;
    }
  }

}
