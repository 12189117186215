@mixin transition($transition...) {
  transition: $transition;
}

%font-smoothing {
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%transition {
  @include transition(all 400ms cubic-bezier(.445, .5, .55, .95));

}

%transition-slow {
  @include transition(all 800ms cubic-bezier(0.215, 0.61, 0.355, 1));
}

address, ul {
  padding: 0;
  margin: 0;
}

.btn,
button:focus {
  outline   : 0;
  appearance: none;
}

%list-reset,
.list-reset {
  list-style: none;
  padding   : 0;
}

input.text,
input[type="text"],
input[type="date"],
input[type="tel"],
input[type="email"],
input[type="button"],
input[type="submit"],
.input-checkbox,
textarea {
  -webkit-appearance: none;
  border-radius     : 2px;
}

[class^=col] {
  img {
    max-width: 100%;
  }
}

.h1,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin-bottom: 0;
}

#__bs_notify__ {
  bottom       : 0 !important;
  right        : 0 !important;
  border-radius: 0 !important;
  height       : 60px !important;
}

a {
  color: inherit;

  &:hover {
    color          : inherit;
    text-decoration: none;

  }
}

//LINEA VERTICAL DIV

.col-div {
  display        : flex;
  align-items    : center;
  justify-content: center;

  &::after {
    content   : ' ';
    display   : block;
    background: $black;
    height    : 100%;
    width     : 1px;
  }
}

.split-content {
  column-count: 2;
  column-gap  : 30px;
  column-span : all;
  column-fill : auto;

  @media (max-width: 767px) {
    column-count: 1;

    &.is-open {
      span:nth-of-type(n+2) {
        display: block;
      }
    }

    span:nth-of-type(n+2) {
      display: none;
    }
  }
}
