.modal {
  display : none;
  position: fixed;
  top     : 0;
  left    : 0;
  width   : 100%;
  height  : 100%;
  z-index : 1050;
  background: $black;
  color:white;
  text-align: center;
  opacity: 0;

  &.is-open {
    display: block;
    opacity: 1;
  }

  .menu {
    &__ubicacion {
      margin-top: 20px;

      &::after {
        content: "";
        background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iN3B4IiBoZWlnaHQ9IjdweCIgdmlld0JveD0iMCAwIDcgNyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTMuMiAoNzI2NDMpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPlJlY3RhbmdsZSBDb3B5IDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iaG9tZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ2NS4wMDAwMDAsIC0xNzkuMDAwMDAwKSIgZmlsbD0iI0ZGRiI+CiAgICAgICAgICAgIDxnIGlkPSJoZWFkZXIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01LjAwMDAwMCwgNi4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJzdWN1cnNhbGVzIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMDguMDAwMDAwLCAxNDcuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZS1Db3B5LTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE2NS41MDAwMDAsIDI5LjUwMDAwMCkgcm90YXRlKC00NS4wMDAwMDApIHRyYW5zbGF0ZSgtMTY1LjUwMDAwMCwgLTI5LjUwMDAwMCkgIiB4PSIxNjMiIHk9IjI3IiB3aWR0aD0iNSIgaGVpZ2h0PSI1Ij48L3JlY3Q+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==');
        background-repeat: round;
        background-size: contain;
        height: 9px;
        width: 100%;
        display: block;
        margin-top: 20px;
      }

      li{
        display: inline-block;
        & + li {
          &::before {
            content: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iN3B4IiBoZWlnaHQ9IjdweCIgdmlld0JveD0iMCAwIDcgNyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTMuMiAoNzI2NDMpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPlJlY3RhbmdsZSBDb3B5IDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iaG9tZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ2NS4wMDAwMDAsIC0xNzkuMDAwMDAwKSIgZmlsbD0iI0ZGRiI+CiAgICAgICAgICAgIDxnIGlkPSJoZWFkZXIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01LjAwMDAwMCwgNi4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJzdWN1cnNhbGVzIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMDguMDAwMDAwLCAxNDcuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZS1Db3B5LTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE2NS41MDAwMDAsIDI5LjUwMDAwMCkgcm90YXRlKC00NS4wMDAwMDApIHRyYW5zbGF0ZSgtMTY1LjUwMDAwMCwgLTI5LjUwMDAwMCkgIiB4PSIxNjMiIHk9IjI3IiB3aWR0aD0iNSIgaGVpZ2h0PSI1Ij48L3JlY3Q+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==');
            padding-left:5px;
            padding-right: 5px;
            vertical-align: text-bottom;
          }
        }
      }
    
    }

    &__horario {
      @include font(normal,uppercase, 32px, 1.14px, normal);
      margin-top: 20px;

      span {
        font-size: 24px;
      }
    }
  }
}

.modal-overlay {
  width          : 100%;
  height         : 100%;
  display        : flex;
  align-items    : flex-start;
  justify-content: flex-start;
}

.modal-content {
  background: transparent;
  border    : 0;
  width     : 100%;
  height    : 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal.is-open{
  animation: mmfadeIn .4s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.modal {
  animation: mmfadeOut .4s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.modal .modal-content,
.modal .modal-overlay {
  will-change: transform;
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
