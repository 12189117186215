
/**
 * @license
 * MyFonts Webfont Build ID 3757980, 2019-05-09T14:52:37-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: WeekendTabloidJNL by Jeff Levine
 * URL: https://www.myfonts.com/fonts/jnlevine/weekend-tabloid/regular/
 * Copyright: (c) 2009 by Jeffrey N. Levine.  All rights reserved.
 * Licensed pageviews: 20,000
 * 
 * Webfont: HelveticaNeueLTPro-Roman by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-55-roman/
 * Copyright: Copyright &#x00A9; 2014 Monotype Imaging Inc. All rights reserved.
 * Licensed pageviews: 250,000
 * 
 * Webfont: HelveticaNeueLTPro-MdCn by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-helvetica/pro-67-medium-condensed/
 * Copyright: Copyright &#x00A9; 2014 Monotype Imaging Inc. All rights reserved.
 * Licensed pageviews: 250,000
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3757980
 * 
 * © 2019 MyFonts Inc
*/

// Typography Options
$font-title: "WeekendTabloidJNL", sans serif;
$font-body: "HelveticaNeueLTPro-Roman", Helvetica, Arial, sans serif;
$font-black: "HelveticaNeueLTPro-MdCn", Helvetica, Arial, sans serif;

//TYPOGRAPHY 
@mixin font($weight:normal, $style: normal, $size:16px, $spacing:-0.08px, $line-height:20px) {
    font-family: $font-title;
    font-weight: $weight;
    text-transform : $style;
    font-size: $size;
    letter-spacing: $spacing;
    line-height: $line-height;
}

/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/39579c");

  
@font-face {
  font-family: 'WeekendTabloidJNL';
  src: url('../fonts/webfonts/39579C_0_0.eot');
  src: url('../fonts/webfonts/39579C_0_0.eot?#iefix') format('embedded-opentype'),
  url('../fonts/webfonts/39579C_0_0.woff2') format('woff2'),
  url('../fonts/webfonts/39579C_0_0.woff') format('woff'),
  url('../fonts/webfonts/39579C_0_0.ttf') format('truetype'),
  url('../fonts/webfonts/39579C_0_0.svg#wf') format('svg');
}
 
  
@font-face {
  font-family: 'HelveticaNeueLTPro-Roman';
  src: url('../fonts/webfonts/39579C_1_0.eot');
  src: url('../fonts/webfonts/39579C_1_0.eot?#iefix') format('embedded-opentype'),
  url('../fonts/webfonts/39579C_1_0.woff2') format('woff2'),
  url('../fonts/webfonts/39579C_1_0.woff') format('woff'),
  url('../fonts/webfonts/39579C_1_0.ttf') format('truetype'),
  url('../fonts/webfonts/39579C_1_0.svg#wf') format('svg');
}
 
  
@font-face {
  font-family: 'HelveticaNeueLTPro-MdCn';
  src: url('../fonts/webfonts/39579C_2_0.eot');
  src: url('../fonts/webfonts/39579C_2_0.eot?#iefix') format('embedded-opentype'),
  url('../fonts/webfonts/39579C_2_0.woff2') format('woff2'),
  url('../fonts/webfonts/39579C_2_0.woff') format('woff'),
  url('../fonts/webfonts/39579C_2_0.ttf') format('truetype'),
  url('../fonts/webfonts/39579C_2_0.svg#wf') format('svg');
}