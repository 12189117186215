.bt-black {
  border-top:1px solid $dark-brown;
}

.bl-black {
  border-left:1px solid $dark-brown;
}

.bb-black {
  border-bottom: 5px solid $black;
}