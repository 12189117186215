//BUTTONS MIX
@mixin btn($bg: $blue, $color: white) {
    @include transition;
    background: $bg;
    color: $color;
    text-align: center;
    padding: 15px 30px;
    display: inline-block;

  // @include media-breakpoint-down(sm) {
  //  width: 100%;
  //  padding:12px 30px 8px;
    // }
    
    img {
        margin-left: 8px;
    }

  &:hover {
    background-color: darken($bg, 5%);
    color:$color;
  }
}

.link {
  &__caret {
    text-decoration: underline;

    &::after {
      content: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iN3B4IiBoZWlnaHQ9IjEzcHgiIHZpZXdCb3g9IjAgMCA3IDEzIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1My4yICg3MjY0MykgLSBodHRwczovL3NrZXRjaGFwcC5jb20gLS0+CiAgICA8dGl0bGU+UGF0aCA0PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9ImhvbWUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMDg4LjAwMDAwMCwgLTE3NzQuMDAwMDAwKSIgZmlsbD0iIzIwMjAyMCI+CiAgICAgICAgICAgIDxnIGlkPSJhYm91dCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEuMDAwMDAwLCA5NTUuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0idHh0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3ODAuMDAwMDAwLCAzODQuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMTMuMDAwMDAwLCA0MjkuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQYXRoLTQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE5Ni41MDAwMDAsIDEyLjUwMDAwMCkgcm90YXRlKC0yMjUuMDAwMDAwKSB0cmFuc2xhdGUoLTE5Ni41MDAwMDAsIC0xMi41MDAwMDApICIgcG9pbnRzPSIxOTIgOCAxOTIgMTcgMjAxIDgiPjwvcG9seWdvbj4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==');
      margin-left: 5px;
    }
  }
}