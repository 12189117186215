.swiper-button-next,
.swiper-button-prev {
  @extend %a;
  background-image: none;
}

.slider {

  .swiper {
    &-buttons {
      position: relative;
      display: flex;
      justify-content: space-around;
      border:1px solid $black;
      border-bottom:none;
      height: 40px;
      padding: 0;

      .swiper-button-next,
      .swiper-button-prev {
        @extend %a;
        background-image: none;
        position: unset;
        top: 0;
        width: 100%;
        height: 100%;
        padding-top: 11px;
        margin-top: 0;
        z-index: 10;
        cursor: pointer;
        text-align: center;
      }

      &::after {
        content:'';
        width: 1px;
        height: 100%;
        background: $black;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
      }

    }

    &-container {
      border: 1px solid $black;
    }

    &-slide {
      padding: 20px;

      img {
        width: 100%;
      }
    }
  }

}

