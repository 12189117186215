// Frameworks
@import 'base/fonts';
@import 'base/normalize';
@import 'bootstrap/bootstrap';
@import 'plugins/nprogress';

// Libraries
@import 'https://unpkg.com/aos@latest/dist/aos.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.4.2/css/swiper.css';

//General
@import 'base/variables';
@import 'base/global';
@import 'base/spacing';

// Base Components
@import "components/text";
@import "components/loader";
@import "components/header";
@import "components/footer";
@import "components/sections";
// @import "components/titles";
@import "components/buttons";
@import "components/cards";
@import "components/slider";
@import "components/modal";
@import "components/menu";
// @import "components/inputs";

// Layouts
// @import "layouts/error";
@import "layouts/index";

// Plugins
@import 'plugins/utilities';
// @import 'plugins/dev';
